import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Flex,
  Box,
  Text,
  OrderedList,
  ListItem,
  Alert,
  AlertIcon,
  AlertDescription,
  UnorderedList,
} from '@chakra-ui/react';

import Card from 'components/card/Card';

import { AuthContext } from 'contexts/AuthContext';

import Dropzone from 'views/_main/ecommerce/settingsProduct/components/DropzoneCard';

import { apiUploadFile } from 'api';

const UploadPolicyView = ({ existingPolicy = false }) => {
  const { organization } = useContext(AuthContext);
  const navigate = useNavigate();

  const [uploadUUID, setUploadUUID] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setIsUploading] = useState({
    mvr: false,
    autoplus: false,
    quote: false,
    app: false,
  });

  const [uploadedMVR, setUploadedMVRs] = useState([]);
  const [uploadedAutoplus, setUploadedAutoplus] = useState([]);
  const [uploadedQuote, setUploadedQuote] = useState(null);
  const [uploadedApp, setUploadedApp] = useState(null);

  const onFileSelect = async (files: any, title: string) => {
    const type = title.toLowerCase();

    setUploadError(null);
    setIsUploading((prev) => ({ ...prev, [type]: true }));

    let localUUID = uploadUUID;
    for (const file of files) {
      try {
        const uploadResp = await handleFileUpload(file, title, localUUID);
        const fileName = file.name;

        localUUID = uploadResp.uuid;
        setUploadUUID(uploadResp.uuid);

        if (type === 'mvr') {
          setUploadedMVRs((prev) => [...prev, fileName]);
        }

        if (type === 'autoplus') {
          setUploadedAutoplus((prev) => [...prev, fileName]);
        }

        if (type === 'quote') {
          setUploadedQuote(fileName);
        }

        if (type === 'application') {
          setUploadedApp(fileName);
        }
      } catch (error) {
        setUploadError(error);
      }
    }

    setIsUploading((prev) => ({ ...prev, [type]: false }));
  };

  const handleFileUpload = async (
    file: any,
    title: string,
    uuid: null | string,
  ) => {
    const type = title.toLowerCase();

    const payload = new FormData();
    payload.append(type, file);
    payload.append('type', existingPolicy ? 'big' : 'mc');

    return await apiUploadFile(uuid ? uuid : null, payload, organization.token);
  };

  const isButtonDisabled = () => {
    if (
      isUploading.mvr ||
      isUploading.autoplus ||
      isUploading.quote ||
      isUploading.app
    ) {
      return true;
    }

    if (existingPolicy && !uploadedApp) {
      return true;
    }

    return !uploadedQuote;
  };

  const onSubmit = () => {
    navigate(`/admin/policies/validations/${uploadUUID}`);
  };

  const disableOtherUpload =
    (isUploading.mvr ||
      isUploading.autoplus ||
      isUploading.quote ||
      isUploading.app) &&
    uploadUUID === null;

  return (
    <>
      <Flex gap="20px">
        {existingPolicy && (
          <Box width="100%">
            <Dropzone
              title="Application"
              handleFileUpload={onFileSelect}
              isLoading={disableOtherUpload || isUploading.app}
            />
          </Box>
        )}
        <Box width="100%">
          <Dropzone
            title="Quote"
            handleFileUpload={onFileSelect}
            isLoading={disableOtherUpload || isUploading.quote}
          />
        </Box>
        <Box width="100%">
          <Dropzone
            title="MVR"
            handleFileUpload={onFileSelect}
            isLoading={disableOtherUpload || isUploading.mvr}
          />
        </Box>
        <Box width="100%">
          <Dropzone
            title="Autoplus"
            handleFileUpload={onFileSelect}
            isLoading={disableOtherUpload || isUploading.autoplus}
          />
        </Box>
      </Flex>

      {uploadError && (
        <Alert status="error" mt="20px">
          <AlertIcon />
          <AlertDescription>
            <UnorderedList styleType="''">
              {Object.entries(uploadError).map((error) => (
                <ListItem key={error[0]}>{error[1]}</ListItem>
              ))}
            </UnorderedList>
          </AlertDescription>
        </Alert>
      )}

      <Card mt="20px">
        <Flex gap="20px">
          {existingPolicy && (
            <Box w="100%">
              <Text fontWeight="bold" fontSize="2xl">
                Application
              </Text>

              <Box>{uploadedApp || '-'}</Box>
            </Box>
          )}

          <Box w="100%">
            <Text fontWeight="bold" fontSize="2xl">
              Quote
            </Text>

            <Box>{uploadedQuote || '-'}</Box>
          </Box>

          <Box w="100%">
            <Text fontWeight="bold" fontSize="2xl">
              MVR
            </Text>

            <OrderedList>
              {uploadedMVR.map((name) => (
                <ListItem key={name}>{name}</ListItem>
              ))}
            </OrderedList>

            {uploadedMVR.length === 0 && '-'}
          </Box>

          <Box w="100%">
            <Text fontWeight="bold" fontSize="2xl">
              Autoplus
            </Text>

            <OrderedList>
              {uploadedAutoplus.map((name) => (
                <ListItem key={name}>{name}</ListItem>
              ))}
            </OrderedList>

            {uploadedAutoplus.length === 0 && '-'}
          </Box>
        </Flex>
      </Card>

      <Card mt="20px">
        <Button
          variant="lightBrand"
          disabled={isButtonDisabled()}
          onClick={onSubmit}
        >
          Initiate Policy Check
        </Button>
      </Card>
    </>
  );
};

export default UploadPolicyView;
