import { useContext, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import {
  Alert,
  AlertIcon,
  Box,
  AlertDescription,
  Checkbox,
  Flex,
} from '@chakra-ui/react';

import TextField from 'components/fields/TextField';
import DateField from 'components/fields/DateField';

import FormInput from '../../components/FormInput';

import getDateFromString from '../../helpers/getDateFromString';
import getDateString from '../../helpers/getDateString';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import {
  addUnlistedDriver,
  changeUnlistedDriver,
  changeRemarks,
} from 'reducers/submissionFormReducer';

const UnlistedDrivers = () => {
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };
  const dispatch = useAppDispatch();

  const drivers = useAppSelector(
    (state) => state.submissionForm.data.unlisted_drivers,
  );

  const validation = validations.find((v) => v.validation_id === '026');

  useEffect(() => {
    // Create initial state only for the first time data is loaded
    if (
      !validation ||
      !validation.validation_outcome_data?.unlisted_drivers ||
      drivers.length > 0
    )
      return;

    validation.validation_outcome_data.unlisted_drivers.forEach(
      (driver: { name: string; license_number: string }) => {
        dispatch(
          addUnlistedDriver({
            name: driver.name,
            license_number: driver.license_number,
            is_in_household: false,
          }),
        );
      },
    );
  }, [validation, drivers, dispatch]);

  useEffect(() => {
    drivers.forEach((driver, index) => {
      if (!driver.is_in_household) {
        dispatch(
          changeRemarks({
            name: 'remark-026',
            value: `Driver: ${
              driver.name || '???'
            } - Is this driver still in the household?: No - Reason not in household: ${
              driver.no_reason || '???'
            }`,
            key: `unlisted-driver-${index + 1}`,
          }),
        );
      } else {
        dispatch(
          changeRemarks({
            name: 'remark-026',
            value: `Driver: ${
              driver.name
            } - Is this driver still in the household?: Yes - License: ${
              driver.license_number
            } - Carrier: ${driver.carrier || 'N/A'} - Policy #: ${
              driver.policy || 'N/A'
            } - Expiry Date: ${
              driver.expiry_date || 'N/A'
            } - Unlicensed Reason: ${
              driver.unlicensed_reason || 'N/A'
            } - Remarks: ${driver.remarks || 'N/A'}`,
            key: `unlisted-driver-${index + 1}`,
          }),
        );
      }
    });
  }, [drivers, dispatch]);

  const onToggleChange = (index: number, status: boolean) => {
    dispatch(
      changeUnlistedDriver({ name: 'is_in_household', value: status, index }),
    );
  };

  const onDriverChange = (event: any, index: number) => {
    const { name, value } = event.target;

    dispatch(changeUnlistedDriver({ name, value, index }));
  };

  const onDateChange = (date: Date, name: string, index: number) => {
    dispatch(changeUnlistedDriver({ name, index, value: getDateString(date) }));
  };

  if (!validation || !drivers) return null;

  return (
    <Box mt="20px">
      <Alert status="warning" mb="20px" fontSize="14px">
        <AlertIcon />
        <Box w="100%">
          <AlertDescription>{validation.validation_message}</AlertDescription>
        </Box>
      </Alert>

      <Box pl="20px">
        {drivers.map((driver, index: number) => {
          return (
            <Box mt={index !== 0 ? '20px' : ''} key={index}>
              <Box backgroundColor="#ccc" rounded="5px" p="5px" pl="10px">
                <strong>({index + 1}) Driver: </strong> {driver.name}
              </Box>

              <Box mt="10px" mb="10px">
                Is this driver still in the household?
                <Checkbox
                  isChecked={driver.is_in_household}
                  onChange={(e) => onToggleChange(index, true)}
                  ml="20px"
                >
                  Yes
                </Checkbox>
                <Checkbox
                  isChecked={!driver.is_in_household}
                  onChange={(e) => onToggleChange(index, false)}
                  ml="20px"
                >
                  No
                </Checkbox>
              </Box>

              {!driver.is_in_household && (
                <Box>
                  <FormInput
                    label="Reason not in household"
                    name="no_reason"
                    value={driver.no_reason}
                    onChange={(e) => onDriverChange(e, index)}
                  />
                </Box>
              )}

              {driver.is_in_household && (
                <Box>
                  <Flex gap="20px">
                    <FormInput
                      label="License"
                      name="license_number"
                      value={driver.license_number}
                      onChange={(e) => onDriverChange(e, index)}
                    />
                    <FormInput
                      label="Carrier"
                      name="carrier"
                      value={driver.carrier}
                      onChange={(e) => onDriverChange(e, index)}
                    />
                    <FormInput
                      label="Policy #"
                      name="policy"
                      value={driver.policy}
                      onChange={(e) => onDriverChange(e, index)}
                    />
                    <DateField
                      label="Expiry Date"
                      date={getDateFromString(driver.expiry_date)}
                      onChange={(date: Date) =>
                        onDateChange(date, 'expiry_date', index)
                      }
                    />
                  </Flex>

                  <Flex gap="20px" mt="10px">
                    <Box w="40%">
                      <TextField
                        label="If driver is unlicensed, give explanation"
                        name="unlicensed_reason"
                        value={driver.unlicensed_reason}
                        onChange={(e: Event) => onDriverChange(e, index)}
                      />
                    </Box>

                    <Box w="60%">
                      <TextField
                        label="Remarks"
                        name="remarks"
                        value={driver.remarks}
                        onChange={(e: Event) => onDriverChange(e, index)}
                      />
                    </Box>
                  </Flex>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default UnlistedDrivers;
