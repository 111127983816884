import { useEffect, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import Card from 'components/card/Card';

import Storage from 'storage';

import { apiApplicationFormData, apiValidations } from 'api';

import { AuthContext } from 'contexts/AuthContext';
import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import PolicyInformation from './sections/PolicyInformation';
import InsuranceCompanyInformation from './sections/InsuranceCompanyInformation';
import Lessor from './sections/Lessor';
import Applicant from './sections/Applicant';
import PolicyPeriod from './sections/PolicyPeriod';
import Vehicles from './sections/Vehicles';
import Drivers from './sections/Drivers';
import Failures from './sections/Failures';
import Claims from './sections/Claims';
import Convictions from './sections/Convictions';
import RatingInformation from './sections/RatingInformation';
import Remarks from './sections/Remarks';
import Submit from './sections/Submit';
import InsuranceCoverages from './sections/InsuranceCoverages';
import MethodOfPayment from './sections/MethodOfPayment';
import DeclarationOfApplicant from './sections/DeclarationOfApplicant';
import ReportOfBrokerAgent from './sections/ReportOfBrokerAgent';

import { useAppSelector, useAppDispatch } from 'hooks';

import { storeData, resetData } from 'reducers/submissionFormReducer';

import type { ApiApplicationFormData } from 'types/api.types';

const SubmissionView = () => {
  const { uuid } = useParams();
  const { organization } = useContext(AuthContext);
  const dispatch = useAppDispatch();

  const isDataSet = useAppSelector((state) => state.submissionForm.isDataSet);
  const dataPrefix = `app_form_${uuid}`;
  const storedForm = Storage.get(dataPrefix);

  const { error, data: initialData } = useQuery({
    queryKey: [dataPrefix],
    queryFn: () => apiApplicationFormData(uuid, organization.token),
    refetchOnWindowFocus: false,
    enabled: !storedForm,
  }) as {
    error: any;
    data: ApiApplicationFormData;
  };

  const { data: validations } = useQuery({
    queryKey: [`validations_${uuid}`],
    queryFn: () => apiValidations(uuid, organization.token),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(resetData());
  }, []);

  useEffect(() => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (!key || !key.startsWith('policy_pilot_app_form_')) continue;

      if (key.includes(uuid)) continue;

      Storage.removeByKey(key);
    }
  }, [uuid]);

  useEffect(() => {
    if (isDataSet) return;

    if (initialData?.data) {
      dispatch(
        storeData({
          uuid,
          data: initialData.data,
          remarks: initialData.remarks,
        }),
      );
      return;
    }

    if (storedForm) {
      dispatch(
        storeData({
          uuid,
          data: storedForm.data,
          remarks: storedForm?.remarks || {},
          files: storedForm?.files || {},
        }),
      );
    }
  }, [initialData, storedForm, uuid, isDataSet, dispatch]);

  if (!isDataSet) {
    return <Card textAlign="center">Preparing form...</Card>;
  }

  if (!validations) {
    return <Card textAlign="center">Loading validations...</Card>;
  }

  if (error) {
    return (
      <Card textAlign="center">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      </Card>
    );
  }

  return (
    <ApplicationFormContext.Provider
      value={{ validations: validations?.validations || [] }}
    >
      <Failures />
      <PolicyInformation />
      <InsuranceCompanyInformation />
      <Applicant />
      <Lessor />
      <PolicyPeriod />
      <Vehicles />
      <Drivers />
      <Claims />
      <Convictions />
      <RatingInformation />
      <InsuranceCoverages />
      <MethodOfPayment />
      <DeclarationOfApplicant />
      <ReportOfBrokerAgent />
      <Remarks />
      <Submit />
    </ApplicationFormContext.Provider>
  );
};

export default SubmissionView;
