import {
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  FormHelperText,
  FormControl,
} from '@chakra-ui/react';

import Message from '../Message';

import { MessageProps } from '../Message/Message.types';

const FormInput = ({
  label,
  extra,
  placeholder,
  type,
  helpText,
  value,
  onChange,
  message,
  is_mandatory,
  ...rest
}: {
  label?: string;
  extra?: JSX.Element | string;
  placeholder?: string;
  type?: string;
  helpText?: string;
  value?: string;
  onChange?: (event: any) => void;
  message?: MessageProps;
  is_mandatory?: boolean;
  [x: string]: any;
}) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <FormControl>
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={label}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: 'pointer' }}
      >
        {label}
        <Text fontSize="sm" fontWeight="400" ms="2px">
          {extra}
        </Text>
      </FormLabel>

      <Input
        type={type}
        id={label}
        fontWeight="500"
        variant="main"
        placeholder={placeholder}
        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
        h="44px"
        maxH="44px"
        borderColor={!value && is_mandatory ? 'red' : 'inherit'}
        value={value}
        onChange={(e) => onChange(e)}
        {...rest}
      />

      {helpText && <FormHelperText>{helpText}</FormHelperText>}

      {message && <Message {...message} />}
    </FormControl>
  );
};

export default FormInput;
