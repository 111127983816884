// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Layout components
import { MdOutlineCalendarToday } from 'react-icons/md';
import Card from 'components/card/Card';
import BarChart from 'components/charts/BarChart';
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from 'variables/charts';
import RiskReport from 'views/_mypolicy/reports/RiskReport';

// Custom Chakra theme
export default function MarketsReport(props: { [x: string]: any }) {
  // Chakra Color Mode
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const { ...rest } = props;

  const newOptions = {
    ...barChartOptionsConsumption,
    fill: {
      type: 'solid',
      colors: ['#6dc5a7', '#edaa7b', '#dc4e3a'],
    },
    colors: ['#6dc5a7', '#edaa7b', '#dc4e3a'],
  };

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');

  return (
    <>
      <Flex direction="column">
        <FormLabel
          ms="10px"
          htmlFor="quantity"
          fontSize="sm"
          color={textColor}
          fontWeight="bold"
          _hover={{ cursor: 'pointer' }}
        >
          Select an insurance company
        </FormLabel>
        <Select
          fontSize="sm"
          id="quantity"
          variant="main"
          w="50%"
          h="44px"
          maxH="44px"
          fontWeight="400"
          me="20px"
          mb="14px"
          defaultValue="1"
        >
          <option value="1">Aviva</option>
          <option value="2">Carrier</option>
          <option value="3">Carrier</option>
        </Select>
      </Flex>
      <RiskReport mt="20px"></RiskReport>
      <Card
        alignItems="center"
        flexDirection="column"
        w="100%"
        {...rest}
        mb="20px"
      >
        <Flex align="center" w="100%" px="15px" py="10px">
          <Text
            me="auto"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            lineHeight="100%"
          >
            Monthly Premiums
          </Text>
          <Button
            bg={boxBg}
            fontSize="sm"
            fontWeight="500"
            color={textColorSecondary}
            borderRadius="7px"
          >
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me="4px"
            />
            This Year
          </Button>
        </Flex>
        <Box h="240px" mt="auto" w="100%">
          <BarChart
            chartData={barChartDataConsumption}
            chartOptions={newOptions}
          />
        </Box>
      </Card>
    </>
  );
}
