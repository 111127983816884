import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, SimpleGrid } from '@chakra-ui/react';

import Card from 'components/card/Card';
import SelectField from 'components/fields/SelectField';
import DateField from 'components/fields/DateField';

import DateInput from '../../components/DateInput';

import getDateString from '../../helpers/getDateString';
import getDateFromString from '../../helpers/getDateFromString';

import { FILE_TYPE } from '../../constants';

import { changePolicyPeriod } from 'reducers/submissionFormReducer';

const TIME_OPTIONS = [
  { value: 'am', label: '12:01 am' },
  { value: 'pm', label: '12:01 pm' },
];

const PolicyPeriod = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(
    (state) => state.submissionForm.data.policy_period,
  );

  const onDateChange = (date: Date, name: string) => {
    dispatch(changePolicyPeriod({ name, value: getDateString(date) }));
  };

  const onTimeChange = (event: any) => {
    dispatch(
      changePolicyPeriod({
        name: 'effective_date_time',
        value: event.target.value,
      }),
    );
  };

  return (
    <Card mb="20px">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Policy Period
      </Text>

      <SimpleGrid columns={4} spacing="20px" mb="20px">
        <DateInput
          label="Effective Date"
          date={getDateFromString(data.effective_date)}
          onChange={(date: Date) => onDateChange(date, 'effective_date')}
          message={{
            validationId: '092',
            file: FILE_TYPE.TYPE_SAME_DAY_BIND,
            hideRemarks: true,
          }}
        />
        <SelectField
          label="Effective Date time"
          value={data.effective_date_time}
          options={TIME_OPTIONS}
          onChange={onTimeChange}
        />
        <DateField
          label="Expiry Date"
          date={getDateFromString(data.expiry_date)}
          onChange={(date: Date) => onDateChange(date, 'expiry_date')}
        />
      </SimpleGrid>
    </Card>
  );
};

export default PolicyPeriod;
