import { useContext, useMemo } from 'react';

import {
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import Card from 'components/card/Card';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

const Failures = () => {
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };

  const failedValidations = useMemo(() => {
    if (!validations) return [];

    return validations.filter(
      (validation: Validation) => validation.validation_outcome === 'fail',
    );
  }, [validations]);

  if (!failedValidations.length) return null;

  return (
    <Card mb="20px">
      <Text fontWeight="bold" fontSize="2xl">
        Failed validations!
      </Text>

      {failedValidations.map((validation: Validation) => {
        return (
          <Alert status="error" mt="20px" key={validation.validation_id}>
            <AlertIcon />
            <Box>
              <AlertTitle>{validation.validation_name}</AlertTitle>
              <AlertDescription>
                {validation.validation_message}
              </AlertDescription>
            </Box>
          </Alert>
        );
      })}
    </Card>
  );
};

export default Failures;
