import { forwardRef } from 'react';

import {
  FormControl,
  FormLabel,
  useColorModeValue,
  Input,
} from '@chakra-ui/react';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import 'assets/css/ReactDatePicker.css';

const DateField = ({
  label,
  date,
  onChange,
  is_mandatory,
}: {
  label: string;
  date: Date;
  onChange: any;
  is_mandatory?: boolean;
}) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  // @ts-ignore
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      onClick={onClick}
      value={value}
      fontWeight="500"
      variant="main"
      _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
      h="44px"
      maxH="44px"
      readOnly
      width="100%"
      // @ts-ignore
      ref={ref}
      borderColor={!value && is_mandatory ? 'red' : 'inherit'}
    />
  ));

  return (
    <FormControl>
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={label}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: 'pointer' }}
      >
        {label}
      </FormLabel>
      {/* @ts-ignore */}
      <DatePicker
        selected={date}
        onChange={(date: Date) => {
          onChange(date);
        }}
        customInput={<ExampleCustomInput />}
        dateFormat="yyyy/MM/dd"
      />
    </FormControl>
  );
};

export default DateField;
