import axios from 'axios';
import config from 'config';

const GET = (url: string, token: string) => {
  return axios
    .get(config.API_ENDPOINT + url, { headers: { Token: token } })
    .then(function (response) {
      return response.data;
    });
};

const POST = (url: string, data: any, token: string) => {
  return axios
    .post(config.API_ENDPOINT + url, data, { headers: { Token: token } })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return new Promise(function (_, reject) {
        // Handle no response errors. Mainly the generic "Network error"
        if (!error.response) {
          reject({ error: 'No response', message: error });
        }

        // Handle invalid request response. Mainly for invalid form data
        if ([400, 401].includes(error.response.status)) {
          reject(error.response.data);
        }

        // Handle all other unforeseen unhandled errors
        reject({ error: 'Unhandled error', message: error });
      });
    });
};

export { GET, POST };
