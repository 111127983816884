// Chakra imports
import {
  Avatar,
  Box,
  Divider,
  Flex,
  Icon,
  SimpleGrid,
  StatGroup,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Select,
} from '@chakra-ui/react';
import MiniCalendar from 'components/calendar/MiniCalendar';
// Layout components
import Card from 'components/card/Card';
import MiniStatistics from 'components/card/MiniStatistics';
import PieChart from 'components/charts/PieChart';
import IconBox from 'components/icons/IconBox';
import { VSeparator } from 'components/separator/Separator';
import { pieChartData, pieChartOptions } from 'variables/charts';

import {
  MdBarChart,
  MdAttachMoney,
  MdAddTask,
  MdFileCopy,
  MdOutlineCalendarToday,
} from 'react-icons/md';

import avatar4 from 'assets/img/avatars/avatar4.png';

const CarrierDropdown = () => {
  return (
    <Box mb="10px" w="100%">
      <Select placeholder="Select option" backgroundColor="#fff">
        <option value="option1" selected>
          All Markets
        </option>
        <option value="option2">Aviva</option>
        <option value="option3">Coachman</option>
        <option value="option4">Echelon</option>
        <option value="option5">Economical</option>
        <option value="option6">Gore Mutual</option>
        <option value="option7">Jevco</option>
        <option value="option8">Peel Mutual</option>
        <option value="option9">Intact</option>
        <option value="option10">Pafco</option>
        <option value="option11">Pembridge</option>
        <option value="option12">RSA</option>
        <option value="option13">SGI</option>
        <option value="option14">Wawanesa</option>
      </Select>
    </Box>
  );
};

// Custom Chakra theme
export default function RiskReport(props: { [x: string]: any }) {
  const { ...rest } = props;
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const newOptions = {
    ...pieChartOptions,
    colors: ['#6dc5a7', '#4318ff', '#edaa7b', '#A020F0', '#dc4e3a'],
    fill: { colors: ['#6dc5a7', '#4318ff', '#edaa7b', '#A020F0', '#dc4e3a'] },
  };

  const newOptions2 = {
    ...pieChartOptions,
    colors: ['#6dc5a7', '#4318ff', '#edaa7b', '#dc4e3a'],
    fill: { colors: ['#6dc5a7', '#4318ff', '#edaa7b', '#dc4e3a'] },
  };

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const cardColor = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset',
  );
  const lessThan = '< 25';
  const greaterThan = '>= 25';
  //variables for km distance

  return (
    <>
      <Card w="100%">
        <Flex justifyContent="space-between" gap="20px">
          <Flex gap="20px" wrap={'wrap'}>
            <Box>
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon
                        w="32px"
                        h="32px"
                        as={MdAttachMoney}
                        color="#6dc5a7"
                      />
                    }
                  />
                }
                name="Gross written premium"
                value="$15,359,493"
                growth="+23%"
                growthText="YoY"
              />
            </Box>

            <Box>
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdBarChart} color="#6dc5a7" />
                    }
                  />
                }
                name="# of Active Policies"
                value="7,348"
                growth="+15%"
                growthText="YoY"
              />
            </Box>

            <Box>
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdBarChart} color="#6dc5a7" />
                    }
                  />
                }
                name="Retention ratio"
                value="87%"
                growth="+3%"
                growthText="YoY"
              />
            </Box>

            <Box>
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdBarChart} color="#6dc5a7" />
                    }
                  />
                }
                name="# of Policies up for Renewal"
                value="647"
                growth=" "
                growthText="In the Next 30 Days"
                valueColor="#dc4e3a"
              />
            </Box>
          </Flex>

          <MiniCalendar selectRange={true}></MiniCalendar>
        </Flex>
      </Card>

      <Flex gap="20px" mt="20px">
        <Box w="50%">
          <Card alignItems="center">
            <CarrierDropdown />
            <Flex
              px={{ base: '0px', '2xl': '10px' }}
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              mb="8px"
            >
              <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
                Underwriting Score Breakdown
              </Text>
            </Flex>

            <PieChart
              h="100%"
              w="100%"
              chartData={[44, 36, 17, 10, 3]}
              chartOptions={newOptions}
            />
            <Card
              bg={cardColor}
              flexDirection="row"
              boxShadow={cardShadow}
              w="100%"
              p="15px"
              px="20px"
              mt="15px"
              mx="auto"
            >
              <Flex>
                <Flex direction="column" py="5px">
                  <Flex align="baseline">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#6dc5a7"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                      mb="5px"
                    >
                      A
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    44%
                  </Text>
                </Flex>
                <VSeparator mx={{ base: '20px' }} />
                <Flex direction="column" py="5px" me="10px">
                  <Flex align="baseline">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#4318ff"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                      mb="5px"
                    >
                      B
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    36%
                  </Text>
                </Flex>
                <VSeparator mx={{ base: '20px' }} />
                <Flex direction="column" py="5px" me="10px">
                  <Flex align="baseline">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#edaa7b"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                      mb="5px"
                    >
                      C
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    17%
                  </Text>
                </Flex>
                <VSeparator mx={{ base: '20px' }} />
                <Flex direction="column" py="5px" me="10px">
                  <Flex align="baseline">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#A020F0"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                      mb="5px"
                    >
                      D
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    10%
                  </Text>
                </Flex>
                <VSeparator mx={{ base: '20px' }} />
                <Flex direction="column" py="5px" me="10px">
                  <Flex align="baseline">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#dc4e3a"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                      mb="5px"
                    >
                      D
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    3%
                  </Text>
                </Flex>
              </Flex>
            </Card>
          </Card>
        </Box>

        <Box w="50%">
          <Card alignItems="center">
            <CarrierDropdown />
            <Flex
              px={{ base: '0px', '2xl': '10px' }}
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              mb="8px"
            >
              <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
                Auto Driving Record Quality
              </Text>
            </Flex>

            <PieChart
              h="100%"
              w="100%"
              chartData={[23, 46, 21, 9]}
              chartOptions={newOptions2}
            />
            <Card
              bg={cardColor}
              flexDirection="row"
              boxShadow={cardShadow}
              w="100%"
              p="15px"
              px="20px"
              mt="15px"
              mx="auto"
            >
              <Flex>
                <Flex direction="column" py="5px">
                  <Flex align="baseline">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#6dc5a7"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                      mb="5px"
                    >
                      Excellent
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    23%
                  </Text>
                </Flex>
                <VSeparator mx={{ base: '20px' }} />
                <Flex direction="column" py="5px" me="10px">
                  <Flex align="baseline">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#4318ff"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                      mb="5px"
                    >
                      Good
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    46%
                  </Text>
                </Flex>
                <VSeparator mx={{ base: '20px' }} />
                <Flex direction="column" py="5px" me="10px">
                  <Flex align="baseline">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#edaa7b"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                      mb="5px"
                    >
                      Average
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    21%
                  </Text>
                </Flex>
                <VSeparator mx={{ base: '20px' }} />
                <Flex direction="column" py="5px" me="10px">
                  <Flex align="baseline">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#A020F0"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                      mb="5px"
                    >
                      Poor
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    9%
                  </Text>
                </Flex>
              </Flex>
            </Card>
          </Card>
        </Box>
      </Flex>

      <Flex gap="20px" mt="20px" mb="20px">
        <Box w="30%">
          <Card>
            <CarrierDropdown />
            <Table variant="striped" colorScheme="blackAlpha">
              <TableCaption placement="top">Age Distribution</TableCaption>
              <Thead>
                <Tr>
                  <Th>Age Band</Th>
                  <Th>Percentage (%)</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>16-20 years</Td>
                  <Td>5.5</Td>
                </Tr>
                <Tr>
                  <Td>21-25 years</Td>
                  <Td>8.2</Td>
                </Tr>
                <Tr>
                  <Td>26-30 years</Td>
                  <Td>10.3</Td>
                </Tr>
                <Tr>
                  <Td>31-35 years</Td>
                  <Td>12.1</Td>
                </Tr>
                <Tr>
                  <Td>36-40 years</Td>
                  <Td>9.7</Td>
                </Tr>
                <Tr>
                  <Td>41-45 years</Td>
                  <Td>8.5</Td>
                </Tr>
                <Tr>
                  <Td>46-50 years</Td>
                  <Td>8.0</Td>
                </Tr>
                <Tr>
                  <Td>51-55 years</Td>
                  <Td>9.9</Td>
                </Tr>
                <Tr>
                  <Td>56-60 years</Td>
                  <Td>8.1</Td>
                </Tr>
                <Tr>
                  <Td>61-65 years</Td>
                  <Td>7.2</Td>
                </Tr>
                <Tr>
                  <Td>66-70 years</Td>
                  <Td>5.6</Td>
                </Tr>
                <Tr>
                  <Td>71-75 years</Td>
                  <Td>3.8</Td>
                </Tr>
                <Tr>
                  <Td>76-80 years</Td>
                  <Td>2.7</Td>
                </Tr>
                <Tr>
                  <Td>81 years and older</Td>
                  <Td>0.4</Td>
                </Tr>
              </Tbody>
            </Table>
          </Card>
        </Box>

        <Box w="30%">
          <Card>
            <CarrierDropdown />
            <Table variant="striped" colorScheme="blackAlpha">
              <TableCaption placement="top">Location Distribution</TableCaption>
              <Thead>
                <Tr>
                  <Th>City</Th>
                  <Th>Policies</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Toronto</Td>
                  <Td>49.8%</Td>
                </Tr>
                <Tr>
                  <Td>Ottawa</Td>
                  <Td>9.8%</Td>
                </Tr>
                <Tr>
                  <Td>Hamilton</Td>
                  <Td>6.7%</Td>
                </Tr>
                <Tr>
                  <Td>Kitchener</Td>
                  <Td>6.5%</Td>
                </Tr>
                <Tr>
                  <Td>London</Td>
                  <Td>4.2%</Td>
                </Tr>
                <Tr>
                  <Td>Brampton</Td>
                  <Td>3.4%</Td>
                </Tr>
                <Tr>
                  <Td>Mississauga</Td>
                  <Td>2.6%</Td>
                </Tr>
                <Tr>
                  <Td>Markham</Td>
                  <Td>2.3%</Td>
                </Tr>
                <Tr>
                  <Td>Vaughan</Td>
                  <Td>2.2%</Td>
                </Tr>
                <Tr>
                  <Td>Windsor</Td>
                  <Td>1.8%</Td>
                </Tr>
                <Tr>
                  <Td>Richmond Hill</Td>
                  <Td>1.6%</Td>
                </Tr>
                <Tr>
                  <Td>Oakville</Td>
                  <Td>1.4%</Td>
                </Tr>
                <Tr>
                  <Td>Oshawa</Td>
                  <Td>1.2%</Td>
                </Tr>
                <Tr>
                  <Td>Barrie</Td>
                  <Td>1.2%</Td>
                </Tr>
                <Tr>
                  <Td>Waterloo</Td>
                  <Td>1.1%</Td>
                </Tr>
                <Tr>
                  <Td>Guelph</Td>
                  <Td>1.0%</Td>
                </Tr>
                <Tr>
                  <Td>Burlington</Td>
                  <Td>0.9%</Td>
                </Tr>
                <Tr>
                  <Td>Milton</Td>
                  <Td>0.9%</Td>
                </Tr>
                <Tr>
                  <Td>Cambridge</Td>
                  <Td>0.8%</Td>
                </Tr>
                <Tr>
                  <Td>St Catherines</Td>
                  <Td>0.8%</Td>
                </Tr>
              </Tbody>
            </Table>
          </Card>
        </Box>

        <Box w="40%">
          <Card>
            <CarrierDropdown />
            <Table variant="striped" colorScheme="blackAlpha">
              <TableCaption placement="top">Car Age Distribution</TableCaption>
              <Thead>
                <Tr>
                  <Th>Car Age Band</Th>
                  <Th>Percentage (%)</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>0-5 years (2019-2024)</Td>
                  <Td>28.5</Td>
                </Tr>
                <Tr>
                  <Td>6-10 years (2014-2018)</Td>
                  <Td>24.3</Td>
                </Tr>
                <Tr>
                  <Td>11-15 years (2009-2013)</Td>
                  <Td>20.8</Td>
                </Tr>
                <Tr>
                  <Td>16-20 years (2004-2008)</Td>
                  <Td>15.6</Td>
                </Tr>
                <Tr>
                  <Td>21-25 years (1999-2003)</Td>
                  <Td>10.8</Td>
                </Tr>
                <Tr>
                  <Td>25+ years (Before 1999)</Td>
                  <Td>0.0</Td>
                </Tr>
              </Tbody>
            </Table>
          </Card>
        </Box>
      </Flex>
    </>
  );
}
