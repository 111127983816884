export const FILE_TYPE = {
  TYPE_PROOF_OF_RESIDENCY: {
    text: 'Proof of residency',
    type: 1,
  },
  TYPE_BILL_OF_SALE: {
    text: 'Bill of Sale',
    type: 2,
  },
  TYPE_UNREPAIRED_DAMAGE: {
    text: 'Unrepaired damage',
    type: 3,
  },
  TYPE_MODIFED_CUSTOMIZED: {
    text: 'Modified / Customized',
    type: 4,
  },
  TYPE_WINTER_TYRE_RECEIPT: {
    text: 'Winter tire receipt',
    type: 5,
  },
  TYPE_COPY_OF_VEHICLE_OWNERSHIP: {
    text: 'Copy of vehicle ownership',
    type: 6,
  },
  TYPE_DRIVER_TRAINING_CERTIFICATE: {
    text: 'Driver training certificate',
    type: 7,
  },
  TYPE_DRIVER_LICENSE_HISTORY: {
    text: 'Driver License History',
    type: 8,
  },
  TYPE_SAME_DAY_BIND: {
    text: 'Same Day Bind',
    type: 9,
  },
  TYPE_NO_POLICIES_FOUND: {
    text: 'No Policies Found',
    type: 10,
  },
  TYPE_LETTER_OF_EXPERIENCE: {
    text: 'Letter of Experience',
    type: 11,
  },
};
