const getValidaitonId = (
  validationId: string | string[],
  validations: Validation[],
) => {
  if (Array.isArray(validationId)) {
    const matchingIds = validationId.filter((id) =>
      validations.some((val) => val.validation_id === id),
    );

    if (!matchingIds.length) {
      return undefined;
    }

    return matchingIds[0];
  }

  return validationId;
};

export default getValidaitonId;
