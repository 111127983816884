import { useContext, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import {
  Alert,
  AlertIcon,
  Box,
  AlertDescription,
  Checkbox,
  Flex,
} from '@chakra-ui/react';

import TextField from 'components/fields/TextField';
import DateField from 'components/fields/DateField';

import FormInput from '../../components/FormInput';
import Divider from '../../components/Divider';

import getDateFromString from '../../helpers/getDateFromString';
import getDateString from '../../helpers/getDateString';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import {
  changeUnlistedVehicles,
  addUnlistedVehicle,
  changeRemarks,
} from 'reducers/submissionFormReducer';

const UnlistedVehicles = () => {
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };
  const dispatch = useAppDispatch();

  const vehicles = useAppSelector(
    (state) => state.submissionForm.data.unlisted_vehicles,
  );

  const validation = validations.find((v) => v.validation_id === '021');

  useEffect(() => {
    // Create initial state only for the first time data is loaded
    if (
      !validation ||
      !validation.validation_outcome_data?.unlisted_vehicles ||
      vehicles.length > 0
    )
      return;

    validation.validation_outcome_data.unlisted_vehicles.forEach(
      (vehicle: { vin: string; name: string }) => {
        dispatch(
          addUnlistedVehicle({
            name: vehicle.name,
            vin: vehicle.vin,
            is_in_household: false,
          }),
        );
      },
    );
  }, [validation, vehicles, dispatch]);

  useEffect(() => {
    vehicles.forEach((vehicle, index) => {
      if (!vehicle.is_in_household) {
        dispatch(
          changeRemarks({
            name: 'remark-021',
            value: `Vehicle: ${
              vehicle.name || '???'
            } - Is this vehicle still in the household?: No - Reason not in household: ${
              vehicle.no_reason || '???'
            }`,
            key: `unlisted-vehicle-${index + 1}`,
          }),
        );
      } else {
        dispatch(
          changeRemarks({
            name: 'remark-021',
            value: `Vehicle: ${
              vehicle.name
            } - Is this vehicle still in the household?: Yes - Carrier: ${
              vehicle.carrier || 'N/A'
            } - Policy #: ${vehicle.policy || 'N/A'} - Expiry Date: ${
              vehicle.expiry_date || 'N/A'
            } - Uninsured Reason: ${
              vehicle.uninsured_reason || 'N/A'
            } - Remarks: ${vehicle.remarks || 'N/A'}`,
            key: `unlisted-vehicle-${index + 1}`,
          }),
        );
      }
    });
  }, [vehicles, dispatch]);

  const onToggleChange = (index: number, status: boolean) => {
    dispatch(
      changeUnlistedVehicles({
        name: 'is_in_household',
        value: status,
        index,
      }),
    );
  };

  const onVehicleChange = (event: any, index: number) => {
    const { name, value } = event.target;

    dispatch(
      changeUnlistedVehicles({
        name,
        value,
        index,
      }),
    );
  };

  const onDateChange = (date: Date, name: string, index: number) => {
    dispatch(
      changeUnlistedVehicles({
        name,
        index,
        value: getDateString(date),
      }),
    );
  };

  if (!validation || !vehicles) return null;

  return (
    <Box mt="20px">
      <Divider mb="10px">Unlisted vehicles</Divider>

      <Alert status="warning" mb="20px" fontSize="14px">
        <AlertIcon />
        <Box w="100%">
          <AlertDescription>{validation.validation_message}</AlertDescription>
        </Box>
      </Alert>

      <Box pl="20px">
        {vehicles.map((vehicle, index: number) => {
          return (
            <Box mt={index !== 0 ? '20px' : ''} key={index}>
              <Box backgroundColor="#ccc" rounded="5px" p="5px" pl="10px">
                <strong>({index + 1}) Vehicle: </strong> {vehicle.name}
              </Box>

              <Box mt="10px" mb="10px">
                Is this vehicle still in the household?
                <Checkbox
                  isChecked={vehicle.is_in_household}
                  onChange={(e) => onToggleChange(index, true)}
                  ml="20px"
                >
                  Yes
                </Checkbox>
                <Checkbox
                  isChecked={!vehicle.is_in_household}
                  onChange={(e) => onToggleChange(index, false)}
                  ml="20px"
                >
                  No
                </Checkbox>
              </Box>

              {!vehicle.is_in_household && (
                <Box>
                  <FormInput
                    label="Reason not in household"
                    name="no_reason"
                    value={vehicle.no_reason}
                    onChange={(e) => onVehicleChange(e, index)}
                  />
                </Box>
              )}

              {vehicle.is_in_household && (
                <Box>
                  <Box mb="10px">Where is the vehicle insured?</Box>
                  <Flex gap="20px">
                    <FormInput
                      label="Carrier"
                      name="carrier"
                      value={vehicle.carrier}
                      onChange={(e) => onVehicleChange(e, index)}
                    />
                    <FormInput
                      label="Policy #"
                      name="policy"
                      value={vehicle.policy}
                      onChange={(e) => onVehicleChange(e, index)}
                    />
                    <DateField
                      label="Expiry Date"
                      date={getDateFromString(vehicle.expiry_date)}
                      onChange={(date: Date) =>
                        onDateChange(date, 'expiry_date', index)
                      }
                    />
                  </Flex>

                  <Flex gap="20px" mt="10px">
                    <Box w="40%">
                      <TextField
                        label="If vehicle is uninsured, give explanation"
                        name="uninsured_reason"
                        value={vehicle.uninsured_reason}
                        onChange={(e: Event) => onVehicleChange(e, index)}
                      />
                    </Box>

                    <Box w="60%">
                      <TextField
                        label="Remarks"
                        name="remarks"
                        value={vehicle.remarks}
                        onChange={(e: Event) => onVehicleChange(e, index)}
                      />
                    </Box>
                  </Flex>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default UnlistedVehicles;
