// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Layout components
import Usa from 'assets/img/dashboards/usa.png';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import {
  MdBarChart,
  MdAttachMoney,
  MdAddTask,
  MdFileCopy,
  MdOutlineCalendarToday,
} from 'react-icons/md';
import Card from 'components/card/Card';
import BarChart from 'components/charts/BarChart';
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from 'variables/charts';

// Custom Chakra theme
export default function PremiumsReport(props: { [x: string]: any }) {
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const { ...rest } = props;

  const newOptions = {
    ...barChartOptionsConsumption,
    fill: {
      type: 'solid',
      colors: ['#6dc5a7', '#edaa7b', '#dc4e3a'],
    },
    colors: ['#6dc5a7', '#edaa7b', '#dc4e3a'],
  };

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');

  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdBarChart} color="#6dc5a7" />}
            />
          }
          name="Earnings"
          value="$350.4"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color="#6dc5a7" />
              }
            />
          }
          name="Spend this month"
          value="$642.39"
        />
        <MiniStatistics growth="+23%" name="Sales" value="$574.34" />
        {/* <MiniStatistics
              endContent={<Flex me='-16px' mt='10px'>
                  <FormLabel htmlFor='balance'>
                      <Avatar src={Usa} />
                  </FormLabel>
                  <Select id='balance' variant='mini' mt='5px' me='0px' defaultValue='usd'>
                      <option value='usd'>USD</option>
                      <option value='eur'>EUR</option>
                      <option value='gba'>GBA</option>
                  </Select>
              </Flex>}
              name='Your balance'
              value='$1,000' />
          <MiniStatistics
              startContent={<IconBox
                  w='56px'
                  h='56px'
                  bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
                  icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />} />}
              name='New Tasks'
              value='154' />
          <MiniStatistics
              startContent={<IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />} />}
              name='Total Projects'
              value='2935' /> */}
      </SimpleGrid>
      <Card
        alignItems="center"
        flexDirection="column"
        w="100%"
        {...rest}
        mb="20px"
      >
        <Flex align="center" w="100%" px="15px" py="10px">
          <Text
            me="auto"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            lineHeight="100%"
          >
            Monthly Premiums
          </Text>
          <Button
            bg={boxBg}
            fontSize="sm"
            fontWeight="500"
            color={textColorSecondary}
            borderRadius="7px"
          >
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me="4px"
            />
            This Year
          </Button>
        </Flex>
        <Box h="240px" mt="auto" w="100%">
          <BarChart
            chartData={barChartDataConsumption}
            chartOptions={newOptions}
          />
        </Box>
      </Card>
    </>
  );
}
