import { useContext } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import {
  Text,
  Box,
  Flex,
  Button,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';

import { DeleteIcon } from '@chakra-ui/icons';

import Card from 'components/card/Card';
import DateField from 'components/fields/DateField';

import FormInput from '../../components/FormInput';
import CheckBoxInput from '../../components/CheckBoxInput';
import getValidationMessage from '../../components/Message/getValidationMessage';
import Message from '../../components/Message';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import getDateString from '../../helpers/getDateString';
import getDateFromString from '../../helpers/getDateFromString';

import {
  changeAccidentsAndClaims,
  addAccidentsAndClaims,
  removeAccidentsAndClaims,
} from 'reducers/submissionFormReducer';

const Claims = () => {
  const { validations } = useContext(ApplicationFormContext) as {
    validations: any;
  };
  const dispatch = useAppDispatch();

  const claims = useAppSelector(
    (state) => state.submissionForm.data.accidents_and_claims,
  );

  const onClaimChange = (event: any, index: number) => {
    const { name, value, checked, type } = event.target;
    const payload = type === 'checkbox' ? checked : value;

    dispatch(changeAccidentsAndClaims({ name, index, value: payload }));
  };

  const onDateChange = (date: Date, name: string, index: number) => {
    dispatch(
      changeAccidentsAndClaims({ name, index, value: getDateString(date) }),
    );
  };

  const addNewClaim = () => {
    dispatch(addAccidentsAndClaims());
  };

  const removeClaim = (_: any, index: number) => {
    dispatch(removeAccidentsAndClaims(index));
  };

  return (
    <Card mb="20px">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Previous Accidents and Insurance Claims
      </Text>

      <Text fontSize="xs">
        Give details of all accidents or claims arising from the ownership, use
        or operation of any automobile by the applicant or any listed driver
        during the last 6 years. The coverages are: BI - Bodily Injury, PD -
        Property Damage, AB - Accident Benefits, DCPD - Direct , Compensation -
        Property Damage, UA - Uninsured Automobile, Coll- Collision, AP - All
        Perils, Comp - Comprehensive, SP - Specified Perils
      </Text>

      {claims.map((claim, index) => {
        return (
          <Flex gap="20px" mt="20px" key={index}>
            <Flex w="6%">
              <FormInput
                label="Driver(No.)"
                name="driver_nr"
                value={claim.driver_nr}
                onChange={(e) => onClaimChange(e, index)}
              />
            </Flex>

            <Flex w="6%">
              <FormInput
                label="Auto(No.)"
                name="auto_nr"
                value={claim.auto_nr}
                onChange={(e) => onClaimChange(e, index)}
              />
            </Flex>

            <Flex w="10%">
              <DateField
                label="Date"
                date={getDateFromString(claim.date)}
                onChange={(date: Date) => onDateChange(date, 'date', index)}
              />
            </Flex>

            <Flex w="35%" flexFlow="wrap">
              <Flex w="100%">
                <Box
                  w="100%"
                  textAlign="center"
                  borderBottom="1px solid #ccc"
                  mb="10px"
                >
                  Coverage Claim Paid Under
                </Box>
              </Flex>
              <Flex gap="20px" flexFlow="wrap">
                <CheckBoxInput
                  name="is_bi"
                  isChecked={claim.is_bi}
                  onChange={(e) => onClaimChange(e, index)}
                >
                  BI
                </CheckBoxInput>

                <CheckBoxInput
                  name="is_pd"
                  isChecked={claim.is_pd}
                  onChange={(e) => onClaimChange(e, index)}
                >
                  PD
                </CheckBoxInput>

                <CheckBoxInput
                  name="is_ab"
                  isChecked={claim.is_ab}
                  onChange={(e) => onClaimChange(e, index)}
                >
                  AB
                </CheckBoxInput>

                <CheckBoxInput
                  name="is_dcpd"
                  isChecked={claim.is_dcpd}
                  onChange={(e) => onClaimChange(e, index)}
                >
                  DCPD
                </CheckBoxInput>

                <CheckBoxInput
                  name="is_ua"
                  isChecked={claim.is_ua}
                  onChange={(e) => onClaimChange(e, index)}
                >
                  UA
                </CheckBoxInput>

                <CheckBoxInput
                  name="is_coll_ap"
                  isChecked={claim.is_coll_ap}
                  onChange={(e) => onClaimChange(e, index)}
                >
                  Coll/AP
                </CheckBoxInput>

                <CheckBoxInput
                  name="is_comp_sp"
                  isChecked={claim.is_comp_sp}
                  onChange={(e) => onClaimChange(e, index)}
                >
                  Comp/SP
                </CheckBoxInput>
              </Flex>
            </Flex>

            <Flex w="10%">
              <FormInput
                label="Amount Paid "
                name="paid"
                value={claim.paid}
                onChange={(e) => onClaimChange(e, index)}
              />
            </Flex>

            <Flex w="20%">
              <FormInput
                label="Details"
                name="details"
                value={claim.details}
                onChange={(e) => onClaimChange(e, index)}
              />
            </Flex>

            <Flex w="5%">
              <Button mt="30px" onClick={(e) => removeClaim(e, index)}>
                <DeleteIcon />
              </Button>
            </Flex>
          </Flex>
        );
      })}

      {getValidationMessage(validations, '032') && (
        <Message validationId="032" />
      )}

      <Box mt="20px">
        <Button variant="brand" onClick={addNewClaim}>
          Add new
        </Button>
      </Box>
    </Card>
  );
};

export default Claims;
