import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, SimpleGrid, Box, Alert, AlertIcon } from '@chakra-ui/react';

import Card from 'components/card/Card';

import FormInput from '../../components/FormInput';

import { changeLessor } from 'reducers/submissionFormReducer';

const Lessor = () => {
  const dispatch = useAppDispatch();

  const lessor = useAppSelector((state) => state.submissionForm.data.lessor);
  const isMandatory = lessor._info.is_mandatory;

  const onLessorChange = (event: any) => {
    const { name, value } = event.target;

    dispatch(changeLessor({ name, value }));
  };

  return (
    <Card mb="20px">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Lessor (if applicable)
      </Text>

      <SimpleGrid columns={5} spacing="20px">
        <FormInput
          label="Name"
          name="name"
          value={lessor.name}
          onChange={onLessorChange}
          is_mandatory={isMandatory}
        />
        <FormInput
          label="Address"
          name="address"
          value={lessor.address}
          onChange={onLessorChange}
          is_mandatory={isMandatory}
        />
        <FormInput
          label="City"
          name="city"
          value={lessor.city}
          onChange={onLessorChange}
          is_mandatory={isMandatory}
        />
        <FormInput
          label="Province code"
          name="province_code"
          value={lessor.province_code}
          onChange={onLessorChange}
          is_mandatory={isMandatory}
        />
        <FormInput
          label="Postal code"
          name="postal_code"
          value={lessor.postal_code}
          onChange={onLessorChange}
          is_mandatory={isMandatory}
        />
      </SimpleGrid>

      {isMandatory && (
        <Alert status="warning" fontSize="12px" mt="20px">
          <AlertIcon />
          <Box>
            "#5a Rent or Lease" coverage detected, lessor information is
            mandatory.
          </Box>
        </Alert>
      )}
    </Card>
  );
};

export default Lessor;
