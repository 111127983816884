import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdHome } from 'react-icons/md';

import UsersOverview from 'views/_main/users/overview';
import ApplicationsDataTables from 'views/_main/applications/dataTables';
import NewUser from 'views/_main/users/newUser';

import ValidatedPolicy from 'views/_mypolicy/ValidatedPolicy';
import RiskReport from 'views/_mypolicy/reports/RiskReport';
import PremiumsReport from 'views/_mypolicy/reports/PremiumsReport';
import MarketsReport from 'views/_mypolicy/reports/MarketsReport';

import UploadPolicyView from 'views/upload_policy';
import SubmissionView from 'views/submission';

const routes = [
  {
    name: 'Policies',
    path: '/policies',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Policies',
        layout: '/admin',
        path: '/policies/history',
        component: <ApplicationsDataTables />,
      },
      {
        name: 'Analytics',
        layout: '/admin',
        path: '/policies/riskreport',
        component: <RiskReport />,
      },
      {
        name: 'Create New Policy',
        layout: '/admin',
        path: '/policies/new',
        component: <UploadPolicyView />,
      },
      {
        name: 'Validate Existing Policy',
        layout: '/admin',
        path: '/policies/existing',
        component: <UploadPolicyView existingPolicy />,
      },
      {
        name: 'Submission',
        layout: '/admin',
        path: '/policies/submission/:uuid',
        component: <SubmissionView />,
        show: false,
      },
      {
        name: 'Validated Policy',
        layout: '/admin',
        path: '/policies/validations/:id',
        component: <ValidatedPolicy />,
        show: false,
      },
    ],
  },
  {
    name: 'Users',
    path: '/users',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'New User',
        layout: '/admin',
        path: '/users/new',
        exact: false,
        component: <NewUser />,
      },
      {
        name: 'Manage Users',
        layout: '/admin',
        path: '/users/manage',
        component: <UsersOverview />,
      },
      {
        name: 'User Reports',
        path: '/users/reports',
        collapse: true,
        items: [
          {
            name: 'Risk Report',
            layout: '/admin',
            path: '/users/reports/risk',
            exact: false,
            component: <RiskReport />,
          },
          {
            name: 'Market Report',
            layout: '/admin',
            path: '/users/reports/markets',
            exact: false,
            component: <MarketsReport />,
          },
          {
            name: 'Premium Report',
            layout: '/admin',
            path: '/users/reports/premiums',
            exact: false,
            component: <PremiumsReport />,
          },
        ],
      },
    ],
  },
];

export default routes;
