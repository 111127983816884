const CACHE_KEY_PREFIX = 'policy_pilot_';

const store = (key: string, data: any) => {
  localStorage.setItem(CACHE_KEY_PREFIX + key, JSON.stringify(data));
};

const get = (key: string) => {
  const data = localStorage.getItem(CACHE_KEY_PREFIX + key);
  if (!data) return null;
  return JSON.parse(data);
};

const remove = (key: string) => {
  localStorage.removeItem(CACHE_KEY_PREFIX + key);
};

const removeByKey = (key: string) => {
  localStorage.removeItem(key);
};

const actions = {
  store,
  get,
  remove,
  removeByKey,
};

export default actions;
