import { Box } from '@chakra-ui/react';

import SelectField from 'components/fields/SelectField';

import Message from '../Message';

import { MessageProps } from '../Message/Message.types';

const SelectInput = ({
  label,
  options,
  onChange,
  message,
  ...rest
}: {
  message?: MessageProps;
  onChange?: (event: any) => void;
  [x: string]: any;
}) => {
  return (
    <Box>
      <SelectField
        label={label}
        options={options}
        onChange={onChange}
        {...rest}
      />
      {message && <Message {...message} />}
    </Box>
  );
};

export default SelectInput;
