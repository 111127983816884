import { Box, Center, Flex, Heading, Image } from '@chakra-ui/react';

import Footer from 'components/footer/FooterAuthDefault';
import ModeToggle from 'components/modeToggle/ModeToggle';

import policy from 'assets/img/others/ppgrey.png';

const Background = ({ children }: { children: JSX.Element }) => {
  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh',
          xl: '97vh',
        }}
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '50px', md: '0px' }}
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >
        {children}
        <Box
          display={{ base: 'none', lg: 'block' }}
          h="100%"
          minH="100vh"
          w={{ lg: '50vw', '2xl': '44vw' }}
          position="absolute"
          right="0px"
          bgGradient="linear(to-b, brand.50, brand.400)"
          borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
          textAlign="center"
        >
          <Center h="100%">
            <Box>
              <Heading
                fontSize={42}
                color="#fff"
                whiteSpace="pre-line"
                textAlign="center"
              >
                Welcome to
              </Heading>
              <Image mt="40px" mb="30%" src={policy}></Image>
            </Box>
          </Center>
        </Box>
        <Footer />
      </Flex>
      <ModeToggle />
    </Flex>
  );
};

export default Background;
