import { Checkbox } from '@chakra-ui/react';

const CheckboxField = ({
  isChecked,
  onChange,
  children,
  ...rest
}: {
  isChecked?: boolean;
  onChange?: (event: any) => void;
  children: string;
  [x: string]: any;
}) => {
  return (
    <Checkbox isChecked={isChecked} onChange={(e) => onChange(e)} {...rest}>
      {children}
    </Checkbox>
  );
};

export default CheckboxField;
