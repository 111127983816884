import { useContext, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, Box, Flex } from '@chakra-ui/react';

import Card from 'components/card/Card';
import DateField from 'components/fields/DateField';
import SelectField from 'components/fields/SelectField';

import FormInput from '../../components/FormInput';
import CheckBoxInput from '../../components/CheckBoxInput';
import SelectInput from '../../components/SelectInput';
import Message from '../../components/Message';
import UnlistedDrivers from '../../components/UnlistedDrivers';
import UnlistedSpouses from '../../components/UnlistedSpouses';
import Divider from '../../components/Divider';

import { FILE_TYPE } from '../../constants';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import getValidationMessage from '../../components/Message/getValidationMessage';

import getDateString from '../../helpers/getDateString';
import getDateFromString from '../../helpers/getDateFromString';

import {
  changeDriverSummary,
  changeDriver,
  changeDriverVehiclePercentageUse,
  changeMostRecentPolicy,
} from 'reducers/submissionFormReducer';

const MARITAL_STATUS_OPTIONS = [
  { value: '', label: '' },
  { value: 'M', label: 'Married' },
  { value: 'S', label: 'Single' },
];

const SEX_OPTIONS = [
  { value: '', label: '' },
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
];

const PERCENTAGE_OPTIONS = [{ value: '', label: '' }].concat(
  Array.from({ length: 100 }, (_, i) => i + 1).map((i) => ({
    value: i.toString(),
    label: `${i}%`,
  })),
);

const Drivers = () => {
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };
  const dispatch = useAppDispatch();

  const drivers = useAppSelector((state) => state.submissionForm.data.drivers);
  const vehicles = useAppSelector(
    (state) => state.submissionForm.data.vehicles,
  );
  const drivers_summary = useAppSelector(
    (state) => state.submissionForm.data.drivers_summary,
  );
  const most_recent_policy = useAppSelector(
    (state) => state.submissionForm.data.most_recent_policy,
  );

  const validation023 = getValidationMessage(validations, ['023', '085']);
  const validation024 = getValidationMessage(validations, '024');
  const validation026 = getValidationMessage(validations, '026');
  const validation022 = getValidationMessage(validations, '022');
  const validation027 = getValidationMessage(validations, '027');
  const validation028 = getValidationMessage(validations, '028');
  const validation029 = getValidationMessage(validations, '029');
  const validation030 = getValidationMessage(validations, '030');
  const validation034 = getValidationMessage(validations, '034');

  useEffect(() => {
    if (validation026) {
      dispatch(
        changeDriverSummary({
          name: 'is_other_persons_licensed_to_drive',
          value: true,
        }),
      );
    }

    if (validation027) {
      dispatch(
        changeDriverSummary({
          name: 'is_suspended_or_cancelled_last_6_years',
          value: true,
        }),
      );
    }

    if (validation028) {
      dispatch(
        changeDriverSummary({
          name: 'is_cancelled_last_3_years',
          value: true,
        }),
      );
    }

    if (validation029) {
      dispatch(
        changeDriverSummary({
          name: 'is_misrepresentation_last_3_years',
          value: true,
        }),
      );
    }
  }, [dispatch, validation026, validation027, validation028, validation029]);

  const onDriverChange = (event: any, index: number) => {
    const { name, value, checked, type } = event.target;
    const payload = type === 'checkbox' ? checked : value;

    dispatch(
      changeDriver({
        name,
        index,
        value: payload,
      }),
    );
  };

  const onDateChange = (date: Date, name: string, index: number) => {
    dispatch(
      changeDriver({
        name,
        index,
        value: getDateString(date),
      }),
    );
  };

  const onMaritalStatusChange = (event: any, index: number) => {
    dispatch(
      changeDriver({
        name: 'marital_status',
        index,
        value: event.target.value,
      }),
    );
  };

  const onSexChange = (event: any, index: number) => {
    dispatch(
      changeDriver({
        name: 'sex',
        index,
        value: event.target.value,
      }),
    );
  };

  const onVehiclePercentageUseChange = (
    event: any,
    driver_index: number,
    vehicle_id: number,
  ) => {
    dispatch(
      changeDriverVehiclePercentageUse({
        driver_index,
        vehicle_id,
        value: event.target.value,
      }),
    );
  };

  const onDriversSummaryChange = (event: any) => {
    dispatch(
      changeDriverSummary({
        name: event.target.name,
        value: event.target.checked,
      }),
    );
  };

  const onMostRecentPolicyChange = (event: any) => {
    dispatch(
      changeMostRecentPolicy({
        name: event.target.name,
        value: event.target.value,
      }),
    );
  };

  const onMostRecentPolicyDateChange = (date: Date, name: string) => {
    dispatch(
      changeMostRecentPolicy({
        name,
        value: getDateString(date),
      }),
    );
  };

  return (
    <Card mb="20px">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Driver Information
      </Text>
      {drivers.map((driver, index: number) => {
        return (
          <Box key={index} mb="20px">
            <Divider mb="20px">Driver {index + 1}</Divider>

            <Flex gap="20px">
              <Box w="30%">
                <FormInput
                  label="Name as shown on Driver's Licence"
                  name="name"
                  value={driver.name}
                  onChange={(e) => onDriverChange(e, index)}
                  is_mandatory
                />
              </Box>

              <Box w="25%">
                <FormInput
                  label="Driver's Licence Number"
                  name="license"
                  value={driver.license}
                  onChange={(e) => onDriverChange(e, index)}
                  is_mandatory
                />
              </Box>

              <Box w="15%">
                <DateField
                  label="Date of Birth"
                  date={getDateFromString(driver.birth_date)}
                  onChange={(date: Date) =>
                    onDateChange(date, 'birth_date', index)
                  }
                  is_mandatory
                />
              </Box>

              <Box w="10%">
                <SelectField
                  label="Sex"
                  value={driver.sex}
                  options={SEX_OPTIONS}
                  onChange={(e) => onSexChange(e, index)}
                />
              </Box>

              <Box w="20%">
                <SelectInput
                  label="Marital Status"
                  value={driver.marital_status}
                  options={MARITAL_STATUS_OPTIONS}
                  onChange={(e) => onMaritalStatusChange(e, index)}
                />
              </Box>
            </Flex>

            <Flex mt="20px">
              <Box
                w="30%"
                textAlign="center"
                borderBottom="1px"
                borderColor="#ccc"
                mb="5px"
              >
                Date First Licensed in Canada or U.S.
              </Box>
              <Box w="30%" mb="5px" pl="20px">
                <Box textAlign="center" borderBottom="1px" borderColor="#ccc">
                  Other class of licence, if any
                </Box>
              </Box>
              <Box w="20%" mb="5px" pl="20px">
                <Box textAlign="center" borderBottom="1px" borderColor="#ccc">
                  Percentage Use by Each Driver
                </Box>
              </Box>
            </Flex>

            <Flex>
              <Box w="10%" pr="20px">
                <FormInput
                  label="Class"
                  name="license_class"
                  value={driver.license_class}
                  onChange={(e) => onDriverChange(e, index)}
                  is_mandatory
                />
              </Box>

              <Box w="20%">
                <DateField
                  label="Date"
                  date={getDateFromString(driver.licensed_date)}
                  onChange={(date: Date) =>
                    onDateChange(date, 'licensed_date', index)
                  }
                  is_mandatory
                />
              </Box>

              <Box w="10%" pl="20px">
                <FormInput
                  label="Class"
                  name="other_license_class"
                  value={driver.other_license_class}
                  onChange={(e) => onDriverChange(e, index)}
                />
              </Box>

              <Box w="20%" pl="20px">
                <DateField
                  label="Date"
                  date={getDateFromString(driver.other_licensed_date)}
                  onChange={(date: Date) =>
                    onDateChange(date, 'other_licensed_date', index)
                  }
                />
              </Box>

              <Box w="20%" pl="20px">
                <Flex flexFlow={'wrap'}>
                  {vehicles.map((_, vehicleIndex: any) => {
                    return (
                      <Box
                        key={vehicleIndex}
                        w={vehicles.length > 1 ? '50%' : '100%'}
                        pr={(vehicleIndex + 1) % 2 !== 0 ? '10px' : ''}
                        mt={vehicleIndex > 1 ? '10px' : ''}
                      >
                        <SelectField
                          key={vehicleIndex}
                          label={`Auto. ${vehicleIndex + 1}`}
                          value={
                            driver.vehicle_percentage_use[
                              `vehicle_${vehicleIndex + 1}`
                            ]
                          }
                          options={PERCENTAGE_OPTIONS}
                          onChange={(e) =>
                            onVehiclePercentageUseChange(
                              e,
                              index,
                              vehicleIndex + 1,
                            )
                          }
                        />
                      </Box>
                    );
                  })}
                </Flex>
              </Box>
            </Flex>

            <Flex>
              <Box w="30%">
                <Message
                  validationId={['023', '085']}
                  key="driver"
                  index={index + 1}
                  file={FILE_TYPE.TYPE_DRIVER_LICENSE_HISTORY}
                />

                <Box
                  {...(validation023 && validation024
                    ? {
                        borderTop: '1px solid #ccc',
                        pt: '10px',
                        mt: '20px',
                      }
                    : {})}
                >
                  <Message validationId="024" key="driver" index={index + 1} />
                </Box>
              </Box>
            </Flex>

            <Flex mt="20px">
              <Box w="30%">
                <CheckBoxInput
                  name="is_training_certificate_attached"
                  isChecked={driver.is_training_certificate_attached}
                  onChange={(e) => onDriverChange(e, index)}
                  message={{
                    validationId: 'driver_training_certificate',
                    storageKey: 'driver',
                    index: index + 1,
                    file: FILE_TYPE.TYPE_DRIVER_TRAINING_CERTIFICATE,
                    manual: {
                      message: 'Upload Driver Training Certificate file',
                      show: driver.is_training_certificate_attached,
                    },
                    hideRemarks: true,
                  }}
                >
                  Driver Training Certificate Attached?
                </CheckBoxInput>
              </Box>

              <Box w="30%" pl="20px">
                <CheckBoxInput
                  name="is_retiree_discount"
                  isChecked={driver.is_retiree_discount}
                  onChange={(e) => onDriverChange(e, index)}
                >
                  Do driver qualify for Retiree Discount?
                </CheckBoxInput>
              </Box>
            </Flex>

            {getValidationMessage(
              validations,
              ['007', '068', '074'],
              index + 1,
            ) && (
              <>
                <Divider mt="10px">Missing Autoplus</Divider>
                <Message
                  validationId={['007', '068', '074']}
                  key="driver"
                  index={index + 1}
                  file={FILE_TYPE.TYPE_NO_POLICIES_FOUND}
                  hideRemarks
                />
              </>
            )}
          </Box>
        );
      })}

      {validation022 && <UnlistedSpouses />}

      {validation026 && (
        <>
          <Divider>Unlisted drivers</Divider>
          <Box mt="20px" mb="20px">
            <CheckBoxInput
              name="is_other_persons_licensed_to_drive"
              isChecked={drivers_summary.is_other_persons_licensed_to_drive}
              onChange={(e) => onDriversSummaryChange(e)}
            >
              Are any other persons in the household or business licensed to
              drive?
            </CheckBoxInput>

            <UnlistedDrivers />
          </Box>
        </>
      )}

      {validation030 && (
        <>
          <Divider mb="10px">Know Your Customer</Divider>
          <Box mb="20px">
            <Message validationId="030" />
          </Box>
        </>
      )}

      {validation034 && (
        <>
          <Divider mb="10px">Lapses</Divider>
          <Box mb="20px">
            <Message
              validationId="034"
              file={FILE_TYPE.TYPE_LETTER_OF_EXPERIENCE}
            />
          </Box>
        </>
      )}

      <Divider>
        What are the details of the applicant's most recent automobile
        insurance?
      </Divider>

      <Flex gap="20px" mt="20px">
        <Box w="30%">
          <FormInput
            label="Insurance Company"
            name="company_name"
            value={most_recent_policy.company_name}
            onChange={(e) => onMostRecentPolicyChange(e)}
          />
        </Box>

        <Box w="20%">
          <FormInput
            label="Policy No."
            name="policy_no"
            value={most_recent_policy.policy_no}
            onChange={(e) => onMostRecentPolicyChange(e)}
          />
        </Box>

        <Box w="15%">
          <DateField
            label="Expiry Date"
            date={getDateFromString(most_recent_policy.expiry_date)}
            onChange={(date: Date) =>
              onMostRecentPolicyDateChange(date, 'expiry_date')
            }
          />
        </Box>
      </Flex>

      <Divider mt="20px">To the applicant's knowledge...</Divider>

      <Box mt="20px">
        <CheckBoxInput
          name="is_suspended_or_cancelled_last_6_years"
          isChecked={drivers_summary.is_suspended_or_cancelled_last_6_years}
          onChange={(e) => onDriversSummaryChange(e)}
          message={{
            validationId: '027',
            manual: {
              message: 'Please provide details',
              show:
                drivers_summary.is_suspended_or_cancelled_last_6_years &&
                !validation027,
            },
          }}
        >
          Has any driver's licence, vehicle permit etc, issued to the applicant
          or to any person in the household or business been suspended or
          cancelled in the last 6 years?
        </CheckBoxInput>

        <CheckBoxInput
          name="is_cancelled_last_3_years"
          isChecked={drivers_summary.is_cancelled_last_3_years}
          onChange={(e) => onDriversSummaryChange(e)}
          mt="10px"
          message={{
            validationId: '028',
            manual: {
              message: 'Please provide details',
              show: drivers_summary.is_cancelled_last_3_years && !validation028,
            },
          }}
        >
          Has any insurance company cancelled automobile insurance for the
          applicant or any listed driver in the last 3 years?
        </CheckBoxInput>

        <CheckBoxInput
          name="is_misrepresentation_last_3_years"
          isChecked={drivers_summary.is_misrepresentation_last_3_years}
          onChange={(e) => onDriversSummaryChange(e)}
          mt="10px"
          message={{
            validationId: '029',
            manual: {
              message: 'Please provide details',
              show:
                drivers_summary.is_misrepresentation_last_3_years &&
                !validation029,
            },
          }}
        >
          During the last 3 years, has any automobile insurance policy issued to
          the applicant or any listed driver been cancelled or has any claim
          been denied for material misrepresentation?
        </CheckBoxInput>

        <CheckBoxInput
          name="is_fraud"
          isChecked={drivers_summary.is_fraud}
          onChange={(e) => onDriversSummaryChange(e)}
          mt="10px"
          message={{
            validationId: 'fraud',
            manual: {
              message: 'Please provide details',
              show: drivers_summary.is_fraud,
            },
          }}
        >
          Has the applicant or any listed driver been found by a court to have
          committed a fraud connected with automobile insurance?
        </CheckBoxInput>
      </Box>
    </Card>
  );
};

export default Drivers;
