import { POST, GET } from 'utils/api';

const apiAuthenticate = (data: { email: string; password: string }) => {
  return POST('api/organization/signin', data, '');
};

const apiApplicationFormData = (uuid: string, token: string) => {
  return GET(`api/policy/mc/${uuid}/application/form`, token);
};

const apiCreateApplicationPDF = (
  uuid: string,
  data: any,
  remarks: any,
  files: any,
  token: string,
) => {
  return POST(
    `api/policy/mc/${uuid}/application`,
    { data, remarks, files },
    token,
  );
};

const apiValidations = (uuid: string, token: string) => {
  return GET(`api/policy/mc/${uuid}`, token);
};

const apiCreateApplicationFile = (uuid: string, data: any, token: string) => {
  return POST(`api/policy/mc/${uuid}/application/file`, data, token);
};

const apiUploadFile = (uuid: string | null, data: any, token: string) => {
  if (uuid) {
    return POST(`api/policy/upload/${uuid}`, data, token);
  }

  return POST('api/policy/upload', data, token);
};

export {
  apiAuthenticate,
  apiApplicationFormData,
  apiCreateApplicationPDF,
  apiValidations,
  apiCreateApplicationFile,
  apiUploadFile,
};
