const getFailureState = (validations: Validation[], validationId: string) => {
  if (!validations || !validationId) return null;

  const validation = validations.find((v) => v.validation_id === validationId);

  if (!validation) return '';

  return validation.validation_outcome;
};

export default getFailureState;
