import { FormControl } from '@chakra-ui/react';

import DateField from 'components/fields/DateField';

import Message from '../Message';

import { MessageProps } from '../Message/Message.types';

const DateInput = ({
  label,
  date,
  onChange,
  is_mandatory,
  message,
}: {
  label: string;
  date: Date;
  onChange: any;
  is_mandatory?: boolean;
  message?: MessageProps;
}) => {
  return (
    <FormControl>
      <DateField
        label={label}
        date={date}
        onChange={(e: Date) => onChange(e)}
        is_mandatory={is_mandatory}
      />

      {message && <Message {...message} />}
    </FormControl>
  );
};

export default DateInput;
