type RowObj = {
  name: string;
  status: string;
  date: string;
  uuid: string;
  carrier: string;
  broker: string;
  underwriter: string;
  progress: number;
};

const tableDataComplex: RowObj[] = [
  {
    name: '#780768',
    progress: 75.5,
    status: 'Approved',
    date: '11 July 2024',
    uuid: 'A',
    carrier: 'Economical',
    broker: 'Diana K.',
    underwriter: '$2,345.43',
  },
  {
    name: '#567884',
    progress: 25.5,
    status: 'Failed',
    date: '11 July 2024',
    uuid: 'F',
    carrier: 'Intact',
    broker: 'Aren M.',
    underwriter: '$1,763.56',
  },
  {
    name: '#910156',
    progress: 90,
    status: 'Caution',
    date: '11 July 2024',
    uuid: 'C',
    carrier: 'Aviva',
    broker: 'Vitalii S.',
    underwriter: '$2,654.78',
  },
  {
    name: '#112102',
    progress: 50.5,
    status: 'Approved',
    date: '11 July 2024',
    uuid: 'B',
    carrier: 'Pembridge',
    broker: 'Matthew R.',
    underwriter: '$3,564.32',
  },
];
export default tableDataComplex;
