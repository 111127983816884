import {
  Select,
  FormControl,
  FormLabel,
  useColorModeValue,
} from '@chakra-ui/react';

const SelectField = ({
  label,
  options,
  onChange,
  ...rest
}: {
  label: string;
  options: {
    value: string;
    label: string;
  }[];
  onChange: (event: any) => void;
  [x: string]: any;
}) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <FormControl>
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={label}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: 'pointer' }}
      >
        {label}
      </FormLabel>
      <Select
        onChange={(e) => onChange(e)}
        fontWeight="500"
        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
        h="44px"
        maxH="44px"
        {...rest}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
