import { Box } from '@chakra-ui/react';

import CheckboxField from 'components/fields/CheckboxField';

import Message from '../Message';

import { MessageProps } from '../Message/Message.types';

const CheckBoxInput = ({
  message,
  children,
  ...rest
}: {
  message?: MessageProps;
  onChange?: (event: any) => void;
  [x: string]: any;
}) => {
  return (
    <Box>
      <CheckboxField {...rest}>{children}</CheckboxField>
      {message && <Message {...message} />}
    </Box>
  );
};

export default CheckBoxInput;
