import { useContext, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useAppSelector } from 'hooks';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Box,
  Link,
} from '@chakra-ui/react';

import Card from 'components/card/Card';

import { apiCreateApplicationPDF } from 'api';

import { AuthContext } from 'contexts/AuthContext';
import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import config from 'config';

const Submit = () => {
  const { uuid } = useParams();
  const { organization } = useContext(AuthContext);
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };

  const is_additional_risk = useAppSelector(
    (state) => state.submissionForm.data.vehicles_summary.is_additional_risk,
  );
  const data = useAppSelector((state) => state.submissionForm.data);
  const remarks = useAppSelector((state) => state.submissionForm.remarks);
  const files = useAppSelector((state) => state.submissionForm.files);

  const hasFailedValidations = useMemo(() => {
    if (!validations) return false;

    return validations.some(
      (validation: any) => validation.validation_outcome === 'fail',
    );
  }, [validations]);

  const onGenerateApplicationPDF = () => {
    apiCreateApplicationPDF(
      uuid,
      data,
      remarks,
      files,
      organization.token,
    ).then((response: any) => {
      window.open(`${response.url}?token=${organization.token}`, '_blank');
    });
  };

  const shouldDisableSubmit = () => {
    if (config.ENV === 'local') return false;
    return hasFailedValidations || is_additional_risk;
  };

  return (
    <Card mb="20px">
      {hasFailedValidations && (
        <Alert status="info" mb="20px">
          <AlertIcon />
          <Box>
            <AlertTitle>Failed validations</AlertTitle>
            <AlertDescription>
              Printing application will not be available until all{' '}
              <Link color="teal.500" onClick={() => window.scrollTo(0, 0)}>
                failed validations
              </Link>{' '}
              are resolved.
            </AlertDescription>
          </Box>
        </Alert>
      )}

      {is_additional_risk && (
        <Alert status="info" mb="20px">
          <AlertIcon />
          <Box>
            <AlertTitle>Carries additional risk</AlertTitle>
            <AlertDescription>
              Printing application will not be available if{' '}
              <strong>
                "Will any of the described automobiles be rented or leased to
                others, or used to carry passengers for compensation or hire, or
                haul a trailer, or carry explosives or radioactive material?"
              </strong>{' '}
              is selected
            </AlertDescription>
          </Box>
        </Alert>
      )}

      <Button
        onClick={onGenerateApplicationPDF}
        disabled={shouldDisableSubmit()}
        _hover={{ bg: 'brand.500' }}
        variant="brand"
      >
        Generate Application PDF
      </Button>
    </Card>
  );
};

export default Submit;
